<template>
  <Member
    title="Dott. Andrea Tettamanti"
    description="Coordinatore dei Fisioterapisti dell’IRCCS Ospedale San Raffaele di Milano"
    text="Lavora come fisioterapista presso il Servizio di Riabilitazione dell’Ospedale san Raffaele di Milano, dove approfondisce le competenze in ambito di fisioterapia cardiorespiratoria. Dal 2008 si dedica anche al Laboratorio di Analisi del Movimento afferente al Corso di Laurea in Fisioterapia. 

Si occupa del coordinamento della riabilitazione di soggetti con disabilità motorie conseguenti eventi acuti o patologie croniche ricoverati presso i reparti di riabilitazione di OSR e pazienti ambulatoriali.

Da novembre 2016 è Direttore della Didattica Professionale del Corso di Laurea in Fisioterapia dell’Università Vita-Salute San Raffaele di Milano.

Da febbraio 2017 è membro del Comitato Ordinatore della Laurea Magistrale in Scienze Riabilitative delle Professioni Sanitarie dell’Università Vita-Salute San Raffaele di Milano e da settembre 2018 Direttore della Didattica e Responsabile dei Progetti di Tirocinio.

È docente Universitario a Contratto di “Fisioterapia Respiratoria” presso il Corso di Laurea in Fisioterapia dell’Università Vita-Salute San Raffaele. Dall’A.A. 2016/2017 è anche docente del modulo di “Cinesiologia Applicata alla Clinica” nonché coordinatore dell’insegnamento. Dal 2016/2017 è docente dei moduli Tirocinio 1, 2 e 3 presso il corso di laurea in Fisioterapia dell’Università Vita-Salute San Raffaele. 

Docente Universitario a Contratto di presso il Corso di Laurea Magistrale in Scienze Riabilitative delle Professioni Sanitarie dei moduli “Misure di outcome”, insegnamento di “Metodologia della ricerca” (docente coordinatore) e “Competenze didattiche in riabilitazione”, insegnamento di “Management Sanitario”, dal 2018/2019 del modulo “Riabilitazione motoria e sfinterica nei soggetti anziani”.

Dal A.A. 2017/2018 è responsabile dell’attività “Riabilitazione neuromotoria” presso la scuola di specialità in Neuropsicologia, Università Vita-Salute San Raffaele.

Dal A.A. 2018/2019 è docente dei Laboratori EMG e GAIT nel corso integrato di Biofisica e Fisiologia, Corso di Laurea Magistrale in Medicina e Chirurgia, primo anno, Università Vita-Salute San Raffaele.

Membro della commissione proponente il Master in Terapia Ricreativa di UniSR in collaborazione con Dynamo Camp.  "
    :formation="[
      'Laurea in Fisioterapia presso l’Università Vita-Salute San Raffaele, 2003',
      'Laurea Magistrale in “Scienze Riabilitative delle Professioni Sanitarie” presso l’Università degli Studi di Milano, 2011',
      '',
      '',
    ]"
    publication="E’ autore di pubblicazioni su riviste nazionali ed internazionali e membro dell’Editorial Board della rivista “Archives of Physiotherapy”. Nel 2011 ha vinto il premio “JOSPT Excellence in Research”."
    :pub="true"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Tettamanti",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
